<template>
  <div class="app-container"><slot></slot></div>
</template>

<style lang="scss" scoped>
.app-container {
  min-height: 100vh;
  padding: 0;
}
</style>

import { cloneDeep } from 'lodash';

const DEFAULT_NS_SETTINGS = {
  showRecentSearches: true,
  recentSearchesMaxCount: 3,
  showGroupTitle: true
};

export default {
  getNSRecentSearches(role) {
    let searches = localStorage.getItem('ns-recent-searches') || null;
    if (!searches) return [];
    return JSON.parse(searches).filter((s) => s.role === role);
  },

  updateNSRecentSearches(item, maxCount, role) {
    let searches = localStorage.getItem('ns-recent-searches') || null;

    let newItem = cloneDeep(item);
    newItem.originalId = item.originalId || item.id;
    newItem.id = `rs-${role}-${newItem.originalId}`;
    newItem.role = role;
    delete newItem.alias;

    if (searches) {
      searches = JSON.parse(searches);
      let duplicateIndex = searches.findIndex(
        (s) => s.id === newItem.id || s.originalId === newItem.originalId
      );
      if (duplicateIndex > -1) searches.splice(duplicateIndex, 1);

      searches.unshift(newItem);
      if (searches.length > maxCount) {
        searches = searches.slice(0, maxCount);
      }
    } else {
      searches = [newItem];
    }

    localStorage.setItem('ns-recent-searches', JSON.stringify(searches));
    return searches.filter((s) => s.role === role);
  },

  getNSSettings(key) {
    let settings = localStorage.getItem('ns-settings') || null;
    if (!settings) return DEFAULT_NS_SETTINGS;

    settings = JSON.parse(settings);
    Object.keys(DEFAULT_NS_SETTINGS).forEach((key) => {
      if (typeof settings[key] === 'undefined') {
        settings[key] = DEFAULT_NS_SETTINGS[key];
      }
    });
    return settings;
  },

  getSetting(key) {
    const settings = localStorage.getItem('ns-settings') || null;
    if (!settings || !key || (key && !(key instanceof String)))
      return undefined;
    return JSON.parse(settings)[key];
  },

  setSetting(key, value) {
    let settings = localStorage.getItem('ns-settings') || null;
    if (!settings || !key || !value || !(key instanceof String)) return;

    settings = JSON.parse(settings);
    settings[key] = value;
    localStorage.setItem('ns-settings', settings);
  },

  updateNSSettings(data) {
    localStorage.setItem('ns-settings', JSON.stringify(data));
  },

  hideHint() {
    localStorage.setItem('ns-hide-hint', true);
  },

  getHintState() {
    return localStorage.getItem('ns-hide-hint') || false;
  }
};

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import {
  BAlert,
  BBadge,
  BButton,
  BCol,
  BCollapse,
  BContainer,
  BDropdown,
  BDropdownDivider,
  BDropdownGroup,
  BDropdownItem,
  BDropdownItemButton,
  BDropdownText,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BModal,
  BPagination,
  BPopover,
  BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  BTooltip,
  BButtonGroup,
  BFormInvalidFeedback,
  BFormSpinbutton,
  BFormRow
} from 'bootstrap-vue-next';
import { h } from 'vue';

import Avatar from '@/components/Avatar.vue';
import CopyText from '@/components/CopyText.vue';
import bFormLabel from '@/components/Forms/bFormLabel.vue';
import bInputNumber from '@/components/Forms/bInputNumber.vue';
import InputHasIcon from '@/components/Forms/InputHasIcon.vue';
import multiSelect from '@/components/Forms/VueMultiSelect.vue';
import IconSvg from '@/components/IconSvg.vue';
import Box from '@/components/Layout/Box.vue';
import BoxSimple from '@/components/Layout/BoxSimple.vue';
import TheHeaderSecondRow from '@/components/Layout/TheHeaderSecondRow.vue';
import TextEmbed from '@/components/TextEmbed.vue';
import registerNotify from '@/components/TheNotifications.js';
import TableSorting from '@/components/Table/TableSorting.vue';

export default function registerComponents(app) {
  // Register plugins using app.use() in Vue 3
  registerNotify(app);

  app.component('BFormInput', BFormInput);
  app.component('BButton', BButton);
  app.component('BAlert', BAlert);
  app.component('BFormGroup', BFormGroup);
  app.component('BForm', BForm);
  app.component('BCol', BCol);
  app.component('BRow', BRow);
  app.component('BContainer', BContainer);
  app.component('BInputGroup', BInputGroup);
  app.component('BDropdownText', BDropdownText);
  app.component('BDropdownGroup', BDropdownGroup);
  app.component('BDropdownDivider', BDropdownDivider);
  app.component('BDropdownItem', BDropdownItem);
  app.component('BTab', BTab);
  app.component('BTabs', BTabs);
  app.component('BPopover', BPopover);
  app.component('BCollapse', BCollapse);
  app.component('BSpinner', BSpinner);
  app.component('BBadge', BBadge);
  app.component('BDropdown', BDropdown);
  app.component('BTooltip', BTooltip);
  app.component('BFormTextarea', BFormTextarea);
  app.component('BPagination', BPagination);
  app.component('BFormCheckboxGroup', BFormCheckboxGroup);
  app.component('BFormSpinbutton', BFormSpinbutton);
  app.component('BFormCheckbox', BFormCheckbox);
  app.component('BCheckbox', BFormCheckbox);
  app.component('BTable', BTable);
  app.component('BFormSelect', BFormSelect);
  app.component('BDropdownItemButton', BDropdownItemButton);
  app.component('BModal', BModal);
  app.component('BFormRadio', BFormRadio);
  app.component('BFormRadioGroup', BFormRadioGroup);
  app.component('BFormRow', BFormRow);
  app.component('BButtonGroup', BButtonGroup);
  app.component('BFormInvalidFeedback', BFormInvalidFeedback);

  // Register components using app.component() in Vue 3
  app.component('Avatar', Avatar);
  app.component('TextEmbed', TextEmbed);
  app.component('BInputNumber', bInputNumber);
  app.component('BFormLabel', bFormLabel);
  app.component('CopyText', CopyText);
  app.component('InputHasIcon', InputHasIcon);
  app.component('MultiSelect', multiSelect);
  app.component('SvgIcon', IconSvg);
  app.component('Box', Box);
  app.component('BoxSimple', BoxSimple);
  app.component('TheHeaderSecondRow', TheHeaderSecondRow);
  app.component('TableSorting', TableSorting);

  app.component('VueDatePicker', VueDatePicker);

  // Register `v-style` component inline using `app.component`
  app.component('VStyle', {
    render() {
      const defaultSlot = this.$slots.default;
      return h('style', defaultSlot ? defaultSlot() : undefined);
    }
  });
}

<template>
  <!--
  Example Usage:
  <box-simple>
    <template #header>
      <h4 class="mb-0">Header Slot</h4>
    </template>
    <template #actions>
      <button>Edit</button>
      <button>Delete</button>
    </template>
    <p>Main content goes here.</p>
    <template #footer>
      <em>Footer Slot</em>
    </template>
  </box-simple>
  -->
  <div class="box">
    <div v-if="$slots.header" class="box-header py-3">
      <div class="box-header__title px-4">
        <slot name="header" />
      </div>
      <div class="box-header__actions px-3 gap-1 flex-wrap">
        <slot name="actions" />
      </div>
      <vertical-loader v-if="props.loading" />
    </div>
    <slot />
    <div v-if="$slots.footer" class="box-footer px-4 py-3">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import VerticalLoader from '@/components/UI/VerticalLoader.vue';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
});
</script>

<style lang="scss" scoped>
.box {
  border-radius: $border-radius-new;
  background: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color);
  --hp-box-header-height: 54px;
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bs-border-color);
  position: relative;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 1rem;
  min-height: var(--hp-box-header-height);
}
.box-header__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.box-header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }
}

.box-footer {
  border-top: 1px solid var(--bs-border-color);
}
</style>

<template>
  <div
    class="overlay"
    :class="{ 'is-opened': isOpened }"
    @click="clickHandler"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      isOpened: false
    };
  },

  methods: {
    clickHandler() {
      this.$bus.$emit('overlay-click');
    }
  },

  mounted() {
    this.$bus.$on('overlay-on', () => (this.isOpened = true));
    this.$bus.$on('overlay-off', () => (this.isOpened = false));
  },

  beforeUnmount() {
    this.$bus.$off('overlay-on');
    this.$bus.$off('overlay-off');
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-sticky;
  // TODO: Nikita, this is color from Figma. Please define it.
  background: rgba(189, 189, 189, 0.6);
  opacity: 0;
  pointer-events: none;

  &.is-opened {
    opacity: 1;
    pointer-events: auto;
  }
}

@include color-mode(dark) {
  .overlay {
    // TODO: Nikita, this is color from Figma. Please define it.
    background: #070501c7;
  }
}
</style>

<template>
  <BFormRadioGroup
    v-model="theme"
    :options="themeOptions"
    buttons
    size="sm"
    button-variant="outline-light"
  />
</template>

<script>
import { useTheme } from '@/composables/useTheme';
import { watch } from 'vue';

export default {
  name: 'ThemeSwitcher',
  setup() {
    const { theme, setTheme } = useTheme();

    watch(theme, (newTheme) => {
      setTheme(newTheme);
    });

    const themeOptions = [
      { text: 'Light', value: 'light' },
      { text: 'Dark', value: 'dark' },
      { text: 'Auto', value: 'auto' }
    ];

    return {
      theme,
      themeOptions
    };
  }
};
</script>

<template>
  <h1 class="title">{{ text }}</h1>
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 0;
  font-size: 1rem;
}
@include media-breakpoint-up(lg) {
  .title {
    font-size: 1.5rem;
  }
}
</style>

<template>
  <div>
    <b-modal
      id="confirm-modal"
      :size="confirmModal.size || 'sm'"
      :title="confirmModal.title"
      :button-size="confirmModal.buttonSize || 'sm'"
      no-close-on-backdrop
      no-close-on-esc
      no-header-close
      body-class="d-none"
      :cancel-title="$t('cancel')"
      header-class="border-bottom-0 pt-4 pb-4"
      @ok="confirmModal.okHandler && confirmModal.okHandler()"
      @cancel="confirmModal.cancelHandler && confirmModal.cancelHandler()"
    />

    <b-modal
      id="info-modal"
      :size="infoModal.size || 'sm'"
      :title="infoModal.title"
      :button-size="confirmModal.buttonSize || 'sm'"
      no-close-on-backdrop
      no-close-on-esc
      no-header-close
      ok-only
      body-class="d-none"
      header-class="border-bottom-0 pt-4 pb-4"
      @ok="infoModal.okHandler && infoModal.okHandler()"
      @cancel="infoModal.cancelHandler && infoModal.cancelHandler()"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      confirmModal: {},
      infoModal: {}
    };
  },

  mounted() {
    this.$bus.$on('modal:confirm', (params) => {
      this.confirmModal = params;
      this.$root.$emit('bv::show::modal', 'confirm-modal');
    });

    this.$bus.$on('modal:info', (params) => {
      this.infoModal = params;
      this.$root.$emit('bv::show::modal', 'info-modal');
    });
  }
};
</script>

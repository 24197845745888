<template>
  <div class="mobile-menu" :class="{ 'is-opened': isOpened }">
    <button
      class="mobile-menu__button"
      :class="{ 'is-opened': isOpened }"
      @click="toggleMenuHandler()"
    >
      <span></span> <span></span> <span></span> <span></span>
    </button>
    <div class="mobile-menu__dropdown" :class="{ 'is-opened': isOpened }">
      <!-- <the-profile-menu class="in-mobile-menu" /> -->
      <navigation
        :expanded="isOpened"
        :items="navigationItems"
        class="in-mobile-menu"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ButtonCampaignAdd from '@/components/ButtonCampaignAdd.vue';
import Navigation from '@/components/Layout/Navigation.vue';
import TheProfileMenu from '@/components/Layout/TheProfileMenu.vue';

export default {
  props: {
    navigationItems: {
      type: Array,
      default: () => []
    }
  },

  components: {
    Navigation,
    TheProfileMenu,
    ButtonCampaignAdd
  },

  data() {
    return {
      isOpened: false,
      actionComponent: {
        name: 'ButtonCampaignAdd'
      }
    };
  },

  methods: {
    toggleMenuHandler() {
      this.isOpened = !this.isOpened;
    },
    closeMenuHandler() {
      this.isOpened = false;
    },
    hideOverlay() {
      this.$bus.$emit();
    },
    ...mapGetters({
      role: 'role'
    })
  },

  mounted() {
    this.$bus.$on('overlay-click', this.closeMenuHandler);
    this.$bus.$on('headerDropdown:show', this.closeMenuHandler);
  },

  beforeUnmount() {
    this.$bus.$off('overlay-click', this.closeMenuHandler);
    this.$bus.$off('headerDropdown:show', this.closeMenuHandler);
  },

  watch: {
    isOpened(val) {
      val ? this.$bus.$emit('overlay-on') : this.$bus.$emit('overlay-off');
    },
    $route() {
      this.isOpened = false;
    },
    role() {
      this.isOpened = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;

  &__button {
    width: 30px;
    height: 23px;
    position: relative;
    transform: rotate(0deg) scale(0.8);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin-left: auto;
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin-left: 12px;
    z-index: $zindex-modal;

    &:focus {
      outline: none;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $lighten;
      border-radius: 3px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 11px;
      }

      &:nth-child(4) {
        top: 22px;
      }
    }

    &.is-opened {
      span {
        &:nth-child(1) {
          top: 7px;
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 7px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }

  &__dropdown {
    position: fixed;
    font-size: 1rem;
    width: 210px;
    left: -100%;
    top: $header-height;
    background: $white;
    background: var(--bs-body-bg);
    transform: translateX(-30px);
    opacity: 0;
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out,
      left 0.3s ease-in-out 0.3s;
    line-height: 1.7rem;
    height: calc(100% - #{$header-height});
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-right: 1px solid var(--bs-border-color);

    &.is-opened {
      display: block;
      transform: translateX(0px);
      transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-in-out;
      opacity: 1;
      left: 0;
    }
  }
}
</style>

import { defineAsyncComponent } from 'vue';

const TargetForm = () => import('@/views/Publisher/Target/Form.vue');
const Targets = () => import('@/views/Publisher/Target/List.vue');
const SentryTest = () => import('@/views/SentryTest.vue');
const RetargetGroups = () =>
  import('@/views/Advertiser/RetargetGroup/List.vue');
const RetargetGroupForm = defineAsyncComponent(
  () => import('@/views/Advertiser/RetargetGroup/Form.vue')
);

const routes = [
  {
    path: '/targets',
    component: Targets,
    name: 'targets',
    meta: {
      roles: ['publisher', 'advert-admin', 'manager'],
      title: 'targets',
      icon: 'hp-target',
      alias: 't'
    }
  },
  {
    path: '/targets/add',
    component: TargetForm,
    name: 'target-add',
    hidden: true,
    meta: {
      title: 'Add target',
      roles: ['publisher', 'advert-admin', 'manager'],
      alias: 't:a'
    }
  },
  {
    path: '/targets/edit/:id',
    component: TargetForm,
    name: 'target-edit',
    hidden: true,
    meta: {
      title: 'Edit target',
      roles: ['publisher', 'advert-admin', 'manager']
    }
  },
  {
    path: '/retarget-groups',
    component: RetargetGroups,
    name: 'retarget-groups',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Retarget groups',
      icon: 'hp-bulsyey',
      alias: 'rtg'
    }
  },
  {
    path: '/retarget-groups/add',
    component: RetargetGroupForm,
    name: 'retarget-group-add',
    hidden: true,
    meta: {
      title: 'Add retarget group',
      roles: ['advert-admin', 'manager'],
      alias: 'rtg:a'
    }
  },
  {
    path: '/retarget-groups/edit/:id',
    component: RetargetGroupForm,
    name: 'retarget-group-edit',
    hidden: true,
    meta: {
      title: 'Edit retarget group',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/sentry-test',
    component: SentryTest,
    name: 'sentry-test',
    hidden: true
  }
];

export default routes;

<template>
  <div>
    <button
      @click="$router.push({ name: 'campaign-add' })"
      class="btn btn-sm btn-primary text-nowrap"
    >
      <svg-icon icon="hp-campaign-add" class="icon-lg" />
      <span>{{ $t('addCampaign') }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.in-mobile-menu {
  padding-top: 15px;
  padding-left: 1px;
  text-transform: capitalize;
  font-weight: normal;
  display: block;

  .icon {
    margin-right: 0;
    width: 60px;
    text-align: center;
    font-size: 1.5rem;
  }
}
.dropdown-item {
  i {
    top: 4px;
  }
}
</style>

const Adunits = () => import('@/views/Publisher/Adunit/List.vue');
const AdunitForm = () => import('@/views/Publisher/Adunit/Form.vue');
const DirectDeals = () => import('@/views/Publisher/DirectDeals/List.vue');
const DirectDealsForm = () => import('@/views/Publisher/DirectDeals/Form.vue');

import {
  DIRECT_DEAL_CREATE,
  DIRECT_DEAL_CREATIVE_CREATE,
  DIRECT_DEAL_CREATIVE_GET,
  DIRECT_DEAL_CREATIVE_LIST,
  DIRECT_DEAL_CREATIVE_UPDATE,
  DIRECT_DEAL_GET,
  DIRECT_DEAL_UPDATE
} from '@/types';

const routes = [
  {
    path: '/adunits',
    component: Adunits,
    name: 'adunits',
    meta: {
      roles: ['publisher', 'advert-admin', 'manager'],
      title: 'adunits',
      icon: 'hp-trello',
      alias: 'au'
    }
  },
  {
    path: '/adunit/add/:type',
    component: AdunitForm,
    name: 'adunit-add',
    hidden: true,
    meta: {
      title: 'Add Ad unit',
      roles: ['publisher', 'advert-admin', 'manager'],
      alias: 'au:a'
    }
  },
  {
    path: '/adunit/edit/:type/:id',
    component: AdunitForm,
    name: 'adunit-edit',
    hidden: true,
    meta: {
      title: 'Edit Ad unit',
      roles: ['publisher', 'advert-admin', 'manager']
    }
  },
  {
    path: '/direct-deals',
    component: DirectDeals,
    name: 'direct-deals',
    meta: {
      roles: ['advert-admin', 'manager'],
      alias: 'dd',
      grants: {
        list: [
          DIRECT_DEAL_CREATE,
          DIRECT_DEAL_UPDATE,
          DIRECT_DEAL_GET,
          DIRECT_DEAL_CREATIVE_CREATE,
          DIRECT_DEAL_CREATIVE_UPDATE,
          DIRECT_DEAL_CREATIVE_LIST,
          DIRECT_DEAL_CREATIVE_GET
        ],
        roles: ['publisher']
      },
      title: 'Direct deals',
      icon: 'hp-deal'
    }
  },
  {
    path: '/direct-deals/add',
    component: DirectDealsForm,
    name: 'direct-deal-add',
    hidden: true,
    meta: {
      title: 'Add Direct Deal',
      roles: ['advert-admin', 'manager'],
      alias: 'dd:a',
      grants: {
        list: [
          DIRECT_DEAL_CREATE,
          DIRECT_DEAL_UPDATE,
          DIRECT_DEAL_GET,
          DIRECT_DEAL_CREATIVE_CREATE,
          DIRECT_DEAL_CREATIVE_UPDATE,
          DIRECT_DEAL_CREATIVE_LIST,
          DIRECT_DEAL_CREATIVE_GET
        ],
        roles: ['publisher']
      }
    }
  },
  {
    path: '/direct-deals/edit/:id',
    component: DirectDealsForm,
    name: 'direct-deal-edit',
    hidden: true,
    meta: {
      title: 'Edit Direct Deal',
      roles: ['advert-admin', 'manager'],
      grants: {
        list: [
          DIRECT_DEAL_CREATE,
          DIRECT_DEAL_UPDATE,
          DIRECT_DEAL_GET,
          DIRECT_DEAL_CREATIVE_CREATE,
          DIRECT_DEAL_CREATIVE_UPDATE,
          DIRECT_DEAL_CREATIVE_LIST,
          DIRECT_DEAL_CREATIVE_GET
        ],
        roles: ['publisher']
      }
    }
  }
];

export default routes;

<template>
  <a
    :class="{
      'dropdown-item dropdown-item-md': true,
      active: active,
      'd-flex': mode !== ':st',
      'justify-content-between': mode !== ':st'
    }"
    :to="item.path"
    @click.stop.prevent="clickEvent(item, $event)"
  >
    <div class="dropdown-item-content overflow-hidden">
      <div class="dropdown-item-title text-truncate">
        <span v-if="showId && item.id">#{{ item.id }}</span>
        {{ item.title }}
      </div>
    </div>
    <b-badge
      v-if="item.alias"
      variant="outline-secondary"
      size="md"
      class="navigation-search__item-alias"
      :class="{ 'large-alias': mode === ':st' }"
    >
      {{ item.alias }}
    </b-badge>
  </a>
</template>

<script>
export default {
  name: 'NavigationSearchItem',

  props: {
    mode: {
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    active: {
      type: Boolean,
      default: false
    },

    showId: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    clickEvent(item, $event) {
      this.$emit('clickEvent', { item: item, event: event });
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation-search__item-alias {
  margin-left: var(--bs-spacer);
  white-space: nowrap;
  font-weight: normal;
  flex-shrink: 0;

  &.large-alias {
    font-size: var(--bs-body-font-size-xs);
  }
}

.dropdown-item {
  &.active {
    .navigation-search__item-alias {
      border-color: transparent !important;
      background-color: var(--bs-body-color);
      color: var(--bs-body-bg);
    }
  }
}
</style>

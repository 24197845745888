<template>
  <div
    class="navigation-search position-relative"
    v-click-outside="closeDropdown"
  >
    <div class="position-relative">
      <vertical-loader v-if="isLoading" />
      <template v-else>
        <div
          v-show="isPlaceholderVisible"
          class="navigation-search__placeholder"
        >
          <span v-if="!isHintHidden" class="me-1">Press</span>
          <kbd>/</kbd>
        </div>
        <span v-if="mode" class="navigation-search__mode">{{ mode }}</span>
        <b-form-input
          ref="searchInput"
          id="navigation-search__input"
          v-model="value"
          :placeholder="placeholder"
          trim
          size="sm"
          :class="{
            'mode-on': mode !== null,
            'is-open': isOpen
          }"
          :debounce="debounce"
          @focus="openDropdown"
          @keydown.up.native="selectionUp"
          @keydown.down.native="selectionDown"
          @keydown.ctrl.enter.exact.native="getUrlAndOpenInNewTab"
          @keydown.ctrl.c.exact.native="getAndCopyUrl"
          @keydown.esc="onEsc"
          @keydown.enter.exact="onEnter"
          @keydown.delete="onDelete"
        />
      </template>

      <div
        class="dropdown-menu"
        :class="{
          'is-open': isOpen && !isLoading && !resultList.length,
          hidden: !isOpen || isLoading || resultList.length > 0,
          'navigation-search__menu': true
        }"
        style="width: 100%"
      >
        <div class="dropdown-item dropdown-item-md text-center" disabled>
          ¯\_(ツ)_/¯
        </div>
      </div>

      <div
        class="dropdown-menu"
        :class="{
          'is-open': isOpen && !isLoading,
          hidden: !isOpen || isLoading || (!isLoading && !resultList.length),
          'navigation-search__menu': true
        }"
        ref="navigation-search-menu"
        role="menu"
        style="width: 100%"
      >
        <template v-if="showRecentSearches">
          <div class="dropdown-group">
            <div v-if="settings.showGroupTitle" class="dropdown-group-title">
              Recent searches
            </div>
            <div
              class="navigation-search__recent-list"
              :class="{ 'no-titles': !settings.showGroupTitle }"
            >
              <navigation-search-item
                v-for="item in recentSearches"
                :key="item.id || item.name"
                :item="item"
                :mode="mode"
                :active="isActiveItem(item)"
                :show-id="false"
                @clickEvent="clickEvent"
              />
            </div>
          </div>
        </template>

        <template v-if="showRecentSearches">
          <div class="dropdown-group">
            <div v-if="settings.showGroupTitle" class="dropdown-group-title">
              Main sections
            </div>
            <div
              class="navigation-search__last-list"
              v-show="!isLoading && result.length > 0"
            >
              <navigation-search-item
                v-for="item in result"
                :key="item.id || item.name"
                :item="item"
                :mode="mode"
                :active="isActiveItem(item)"
                :show-id="false"
                @clickEvent="clickEvent"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <navigation-search-item
            v-for="item in resultList"
            :key="item.id || item.name"
            :item="item"
            :mode="mode"
            :active="isActiveItem(item)"
            @clickEvent="clickEvent"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import NavigationSearchItem from './components/NavigationSearchItem.vue';
import SettingsModal from './components/SettingsModal.vue';
import VerticalLoader from '@/components/UI/VerticalLoader.vue';
import { addRoutes, commands, modes } from './mixins.js';
import Storage from './storage.js';

export default {
  name: 'NavigationSearch',

  components: {
    NavigationSearchItem,
    VerticalLoader,
    SettingsModal
  },

  mixins: [modes, addRoutes, commands],

  data() {
    return {
      value: '',
      isOpen: false,
      localItems: [],
      statsReports: [],
      recentSearches: [],
      selection: null,
      selectionIndex: -1,
      mode: null,
      activeModeModel: null,
      selectingMode: false,
      selectingCommand: false,
      isLoading: false,
      isHintHidden: false,
      noResults: false,
      fuse: null,
      listForSearch: [],
      fuseOptions: {
        keys: ['alias', 'title']
      },
      result: [],
      asyncResult: [],
      asyncResultPagination: {
        limit: 3,
        offset: 0
      },
      settings: {
        showRecentSearches: true,
        recentSearchesMaxCount: 3,
        showGroupTitle: true
      },
      isPlaceholderVisible: true
    };
  },

  watch: {
    value() {
      this.selectionIndex = -1;
      this.selection = null;
      this.selectingMode =
        this.value && this.value.length && this.value[0] === ':' && !this.mode;
      this.selectingCommand =
        this.value && this.value.length && this.value[0] === '/' && !this.mode;

      if (this.isLocalMode) {
        this.fuseSearch();
      } else {
        this.getAsyncList();
      }
    },

    selection(val) {
      if (val === null) this.$refs['navigation-search-menu'].scrollTo(0, 0);
    },

    selectingCommand(val) {
      this.selection = null;
      this.selectionIndex = -1;

      if (val) {
        this.updateFuseList(this.commands);
      } else {
        this.updateFuseList(this.localItems);
      }
    },

    async selectingMode(val) {
      this.selection = null;

      if (val) {
        this.updateFuseList(cloneDeep(this.availableModes));
        return;
      }

      if (this.mode === ':st') {
        if (!this.statsReports.length) {
          await this.getStatsReports();
        } else {
          this.updateFuseList(this.statsReports);
        }
      } else if (this.selectingCommand) {
        this.updateFuseList(this.commands);
      } else {
        this.updateFuseList(this.localItems);
      }
    },

    mode(val) {
      if (val === null) {
        this.updateFuseList(this.localItems);
      }

      if (val === ':st') {
        this.updateFuseList(this.statsReports);
      }
      this.selectionIndex = -1;
      this.selection = null;
      this.$refs['navigation-search-menu'].scrollTo(0, 0);
    },

    role() {
      this.localItems = this.generateLocalList();
      this.updateFuseList(this.localItems);
      this.statsReports = [];
      this.recentSearches = Storage.getNSRecentSearches(this.role);
    },
    placeholder: {
      handler() {
        if (this.placeholder == '') {
          this.isPlaceholderVisible = true;
        } else {
          this.isPlaceholderVisible = false;
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      reports: 'advert/reports',
      role: 'role'
    }),

    placeholder() {
      if (!this.isOpen) return '';

      if (this.mode && this.activeModeModel)
        return `Search ${this.activeModeModel.title}`;
      return 'Search main sections';
    },

    itemsGroupHeader() {
      if (this.selectingCommand) return 'Commands';
      if (this.selectingMode) return 'Search modes';
      if (!this.mode) return 'Main sections';
      return this.activeModeModel.groupTitle;
    },

    isLocalMode() {
      return this.mode === null || this.mode === ':st' || this.selectingCommand;
    },

    debounce() {
      return this.isLocalMode ? 0 : 500;
    },

    showRecentSearches() {
      return (
        this.settings.showRecentSearches &&
        this.recentSearches.length &&
        !this.mode &&
        (!this.value || !this.value.length)
      );
    },

    resultList() {
      if (this.showRecentSearches)
        return [...this.recentSearches, ...this.result];
      return this.isLocalMode ? this.result : this.asyncResult;
    }
  },

  methods: {
    hideHelpHint(event) {
      event.preventDefault();
      Storage.hideHint();
      this.isHintHidden = Storage.getHintState();
    },

    updateSettings() {
      this.settings = Storage.getNSSettings();
    },

    generateLocalList() {
      let list = this.$store.getters.routes
        .map((route) => {
          return {
            id: route.meta.alias || route.name,
            alias: route.meta.alias || '',
            title: this.$t(route.meta.title),
            path: route.path,
            roles: route.meta.roles
          };
        })
        .filter((route) => !route.path.includes('matching'));

      list = [...list, ...this.addRoutes].filter((route) =>
        route.roles.includes(this.$store.getters.role)
      );

      return list;
    },

    openDropdown() {
      if (!this.isOpen) {
        this.isOpen = true;
      }
    },

    closeDropdown() {
      if (this.isOpen) {
        this.isOpen = false;
        if (this.$refs.searchInput) {
          this.$refs.searchInput.blur();
        }
      }
    },

    openNavigationSearch(event) {
      if (event.key !== '/') {
        return;
      }

      if (
        ['input', 'textarea'].includes(
          document.activeElement.tagName.toLowerCase()
        )
      ) {
        return;
      }

      // Exclude contenteditable elements (e.g., CodeMirror and similar editors)
      if (document.activeElement.isContentEditable) {
        return;
      }

      event.preventDefault();
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    },

    focusInput() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    },

    getUrlForHotKey() {
      let url = window.location.origin;
      return this.selection
        ? url + this.selection.path
        : url + this.resultList[0].path;
    },

    getUrlAndOpenInNewTab() {
      if (this.selectingMode) return;
      let url = this.getUrlForHotKey();
      window.open(url);
    },

    async getAndCopyUrl() {
      if (this.selectingMode) return;
      let url = this.getUrlForHotKey();

      try {
        await navigator.clipboard.writeText(url);
        this.$notify('alert', {
          text: 'Copied!',
          variant: 'success',
          time: 1500
        });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },

    updateRecentSearches(item) {
      this.recentSearches = Storage.updateNSRecentSearches(
        item,
        this.settings.recentSearchesMaxCount,
        this.role
      );
    },

    checkDropdownScroll() {
      const selectedEl = this.$refs['navigation-search-menu'].querySelector(
        '.dropdown-item.active'
      );
      if (selectedEl) selectedEl.scrollIntoView({ block: 'center' });
    },

    selectionDown(e) {
      e.preventDefault();
      if (this.selectionIndex === this.resultList.length - 1) return;
      this.selectionIndex++;
      this.selection = this.resultList[this.selectionIndex];
      this.checkDropdownScroll();
    },

    selectionUp(e) {
      e.preventDefault();
      if (this.selectionIndex === 0) return;
      this.selectionIndex--;
      this.selection = this.resultList[this.selectionIndex];
      this.checkDropdownScroll();
    },

    onEsc() {
      this.closeDropdown();
      if (this.$refs.searchInput) {
        this.$refs.searchInput.blur();
      }
    },

    onEnter() {
      if (this.selectingMode) {
        if (this.selection !== null) {
          let mode = this.result.find(
            (mode) => mode.alias === this.selection.alias
          );
          this.mode = mode ? mode.alias : null;
          this.activeModeModel = mode || null;
          this.value = '';
        } else {
          let mode = this.result.length ? this.result[0] : false;
          if (!mode) return;
          this.mode = mode.alias;
          this.activeModeModel = mode;
          this.value = '';
          this.asyncResult = [];
          this.result = [];
        }
      } else if (this.selectingCommand) {
        if (this.selection === null) {
          if (!this.resultList.length) return;
          this.resultList[0].action();
        } else {
          this.selection.action();
        }
        this.closeDropdown();
      } else {
        if (this.selection === null) {
          if (this.resultList.length) {
            this.updateRecentSearches(this.resultList[0]);
            this.$router.push(this.resultList[0].path);
            this.closeDropdown();
          }
        } else {
          this.updateRecentSearches(this.selection);
          this.$router.push(this.selection.path);
          this.closeDropdown();
        }
      }
    },

    clickEvent({ item, event }) {
      if (!this.selectingMode) {
        this.updateRecentSearches(item);
        this.closeDropdown();
      } else {
        event.preventDefault();
        this.activeModeModel = item;
        this.value = '';
        this.mode = item.alias;
        this.$refs.searchInput.focus();
      }
    },

    onDelete() {
      if (this.mode && !this.value.length) {
        this.mode = null;
        this.activeModeModel = null;
      }
    },

    fuseSearch() {
      const query = this.value?.trim();
      if (query === '/') {
        this.result = this.commands;
      }
      if (!query || query === '') {
        if (this.mode === null) this.result = this.localItems;
        if (this.mode === ':st') this.result = this.statsReports;
      } else {
        debounce(() => {
          this.result = this.listForSearch.filter((item) => {
            const combinedText = `${item.title} ${item.alias}`.toLowerCase();
            return combinedText.includes(query.toLowerCase());
          });
        }, 300)();
      }
    },

    updateFuseList(list) {
      this.listForSearch = cloneDeep(list);
      this.fuseSearch();
    },

    getAsyncList() {
      this.isLoading = true;
      let params = { ...this.asyncResultPagination };
      if (this.value.length) {
        params[this.activeModeModel.searchParam] = this.value.toLowerCase();
      }

      this.activeModeModel
        .getOptions({ params: params })
        .then((res) => {
          let list = get(res, 'result', res) || [];
          this.asyncResult = list.map((item) => this.processAsyncItem(item));
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          this.$alertError(e.response.data.detail);
        });
    },

    // admin only
    async getStatsReports() {
      this.isLoading = true;
      await this.fetchReports();
      this.statsReports = this.reports.map((report) => {
        cloneDeep(report);
        const title = `${report.categoryCode
          .charAt(0)
          .toUpperCase()}${report.categoryCode.slice(1)} - ${report.name}`;
        return {
          id: report.id,
          title: title,
          alias: report.code,
          path: this.processRoutePath({ alias: report.code })
        };
      });
      this.updateFuseList(this.statsReports);
      this.result = this.statsReports;
      this.isLoading = false;
    },

    processAsyncItem(item) {
      return {
        id: item.id,
        title: item.name,
        path: this.processRoutePath(item)
      };
    },

    processRoutePath(item) {
      let path = this.activeModeModel.route.path;
      if (!this.activeModeModel.route.params) return path;

      this.activeModeModel.route.params.forEach((p) => {
        if (p.valueKey instanceof Function) {
          path = path.replace(p.param, p.valueKey(item));
        } else {
          path = path.replace(p.param, item[p.valueKey]);
        }
      });

      return path;
    },

    isActiveItem(item) {
      if (!this.selection) return false;
      return this.selection.id === item.id;
    }
  },

  beforeUnmount() {
    window.removeEventListener('keypress', this.openNavigationSearch);
  },

  created() {
    setTimeout(() => {
      this.updateSettings();
      this.isHintHidden = Storage.getHintState();
      window.addEventListener('keypress', this.openNavigationSearch);

      this.recentSearches = Storage.getNSRecentSearches(this.role);
      this.localItems = this.generateLocalList();
      this.listForSearch = this.localItems;
      this.result = this.localItems;
      this.isPlaceholderVisible = true;
    }, 500);
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/hprofits/popover.scss';

.navigation-search {
  width: 100%;
  position: relative;

  @media (max-width: 600px) {
    display: none !important;
  }

  .dropdown-menu {
    margin-top: calc(var(--bs-spacer) * 0.5);
    border-radius: var(--bs-border-radius);
    max-height: 300px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1025;

    &.is-open {
      display: block;
    }

    &.hidden {
      display: none;
    }
  }
}

.navigation-search__mode {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  font-size: var(--bs-body-font-size-sm);
  color: var(--bs-body-color);
  pointer-events: none;
  display: flex;
  align-items: center;
}

.navigation-search__input {
  width: 100%;
  border-radius: var(--bs-border-radius-sm) !important;
  background: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color);
  font-size: var(--bs-body-font-size-sm);
  line-height: var(--bs-body-line-height);
  padding: calc(var(--bs-spacer) * 0.375) calc(var(--bs-spacer) * 0.75);
  padding-left: 2.5rem;
  height: var(--bs-form-element-height-sm);
  min-width: 250px;

  &:focus {
    box-shadow: none;
    border-color: var(--bs-primary) !important;
    outline: 0;
  }

  &.mode-on {
    padding-left: 3.5rem;
    background: var(--bs-white) !important;
  }

  &.is-open {
    background: var(--bs-white) !important;
  }
}

.navigation-search__placeholder {
  position: absolute;
  left: 0.35rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  height: 1.5rem;
  border-radius: 6px;
  background: var(--bs-body-secondary-bg);
  color: var(--bs-icon-color);
  pointer-events: none;
  z-index: 5;

  kbd {
    background: none;
    padding: 0;
    color: inherit;
  }
}

.navigation-search__item-alias {
  font-family: 'Roboto', sans-serif;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 0.8rem !important;
  line-height: 100% !important;
}

.navigation-search__menu {
  --bs-spacer: 0.5rem;
  padding: 0 !important;
  .dropdown-group {
    &:not(:last-child) {
      margin-bottom: var(--bs-spacer);
    }
  }

  .dropdown-group-title {
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-secondary);
    font-size: var(--bs-dropdown-title-font-size);
    font-weight: 400;
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    margin-bottom: var(--bs-spacer);
    margin-top: var(--bs-spacer);
    border-top: var(--bs-border-width) solid var(--bs-border-color);
  }
}
</style>

<template>
  <div>
    <div class="mb-3">
      <b-form-checkbox
        v-model="form.showGroupTitle"
        name="ns-recent-searches"
        switch
        size="sm"
      >
        Show <i>group titles</i>
      </b-form-checkbox>
    </div>

    <div class="mb-3">
      <b-form-checkbox
        v-model="form.showRecentSearches"
        name="ns-recent-searches"
        switch
        size="sm"
      >
        Show Recent searches
      </b-form-checkbox>
    </div>

    <div class="mb-3">
      <label for="ns-recent-searches-count" class="custom-range-label">
        Max Recent searches count: <b>{{ form.recentSearchesMaxCount }}</b>
      </label>
      <div class="custom-form-range">
        <b-form-input
          id="ns-recent-searches-count"
          v-model="form.recentSearchesMaxCount"
          type="range"
          min="1"
          max="5"
          size="sm"
          :disabled="!form.showRecentSearches"
        />
      </div>
    </div>

    <div class="text-end">
      <b-button size="sm" @click="save"> Save </b-button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

import Storage from './../storage.js';

export default {
  data() {
    return {
      form: {
        showRecentSearches: true,
        recentSearchesMaxCount: 3,
        showGroupTitle: true
      }
    };
  },

  methods: {
    save() {
      Storage.updateNSSettings(cloneDeep(this.form));
      this.$emit('form-submit');
    }
  },

  created() {
    this.form = Storage.getNSSettings();
  }
};
</script>

<style lang="scss">
.custom-form-range {
  max-width: 150px;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 15px;
}

.custom-range-label {
  font-size: 14px;
}
</style>

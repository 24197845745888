<template>
  <box>
    <b-row class="align-items-center">
      <b-col cols="12" class="text-sm-center text-md-left" md="6">
        <h1 v-if="title">{{ title }}</h1>
        <p v-if="description" v-html="description"></p>
        <button class="btn btn-primary" @click="$router.go(-1)">
          {{ $t('getBack') }}
        </button>
      </b-col>
      <b-col cols="6" class="d-none d-md-flex">
        <img src="@/assets/images/401.gif" />
      </b-col>
    </b-row>
  </box>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '404'
    },
    description: {
      type: String,
      default: 'Not found'
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 4rem;
}
p {
  font-size: 2rem;
  line-height: 1.5;
}

img {
  display: block;
  margin: 0 auto;
}
</style>

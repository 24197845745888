<template>
  <box-simple class="panel">
    <template #header>
      <h4 class="mb-0">DB Entities</h4>
    </template>
    <template #actions>
      <router-link
        class="btn btn-link-alt p-0 m-0 border-0 d-flex align-items-center text-uppercase"
        :to="{ name: 'add-db-entity' }"
      >
        <svg-icon icon="plus-round" class="me-2" />
        Add entity
      </router-link>
    </template>
    <div class="p-4">
      <b-form-group class="mb-0 pt-2 ps-2 pe-2">
        <b-form-input
          id="search"
          name="search"
          type="text"
          placeholder="Search"
          size="md"
          v-model="query"
        />
      </b-form-group>
      <ul class="list-unstyled p-2 mb-0" v-if="entities.length">
        <entity v-for="item in entities" :item="item" :key="item.id" />
      </ul>
      <div class="text-center p-2" v-else>No entities found</div>
    </div>
    <template #footer>
      <div class="d-flex">
        <div class="pagination p-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="limit"
            class="mb-0"
            size="small"
          />
        </div>
        <!-- <div class="d-flex flex-row-reverse ms-auto p-2">
          <b-form-select
            v-model="limit"
            :options="pageOptions"
            style="width: 70px"
          />
        </div> -->
      </div>
    </template>
  </box-simple>
</template>

<script>
import { mapActions } from 'vuex';

import Entity from '@/views/Dashboard/Admin/components/Entity.vue';

export default {
  components: {
    Entity
  },

  data() {
    return {
      limit: 10,
      currentPage: 1,
      totalRows: 0,
      dbEntities: [],
      currentEntities: [],
      pageOptions: [5, 10, 20],
      query: ''
    };
  },

  computed: {
    entities() {
      if (this.currentEntities.length === 0) return [];
      return this.currentEntities[this.currentPage - 1];
    }
  },

  methods: {
    ...mapActions({
      fetchDBEntities: 'advert/fetchDBEntities'
    }),

    chunk(arr) {
      return Array.from(
        { length: Math.ceil(arr.length / this.limit) },
        (v, i) => arr.slice(i * this.limit, i * this.limit + this.limit)
      );
    },

    setCurrentEntiries(entities) {
      this.currentEntities = this.chunk(entities);
    },

    async setEntities() {
      this.dbEntities = await this.fetchDBEntities();
      this.totalRows = this.dbEntities.length;
      this.setCurrentEntiries(this.dbEntities);
    }
  },

  async created() {
    await this.setEntities();
  },

  watch: {
    limit() {
      this.setCurrentEntiries(this.dbEntities);
    },

    query(val) {
      if (val.length > 2) {
        const filtered = this.dbEntities.filter((entity) =>
          entity.name.toLowerCase().includes(val.toLowerCase())
        );
        this.totalRows = filtered.length;
        this.setCurrentEntiries(filtered);
      } else {
        this.setEntities();
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="header-control">
    <b-button variant="link" @click="toggleDropdown()">
      <div
        class="notification__bell me-3"
        :class="{ 'has-unread-notifications': unreadNotifications }"
      >
        <svg-icon icon="hp-bell" class="icon-menu-md icon-color-normal" />
      </div>
    </b-button>
    <Teleport to="body">
      <Transition
        name="fade"
        @before-enter="$bus.$emit('headerDropdown:show')"
        @after-leave="$bus.$emit('headerDropdown:hide')"
      >
        <div
          v-click-outside="hideHandler"
          v-if="isOpen"
          class="notification-dropdown"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Notifications</h4>
            <button class="btn-link-alt">
              <svg-icon
                icon="hp-close"
                class="icon-lg"
                @click="toggleDropdown"
              />
            </button>
          </div>
          <ContentModeration />
          <BalancesWidget />
          <SspBalancesWidget />
          <ParsersLog />
          <DbEntitiesWidget />
          <!-- <section
              v-if="!emailVerified"
              class="notification notification--danger"
            >
              <h6 class="title">{{ $t('verifyEmail') }}</h6>
              <p>
                <small>
                  {{ $t('verifyEmailText') }}
                  <button
                    class="btn btn-link"
                    @click="requestVerificationEmail"
                  >
                    {{ $t('verifyEmailLink') }}
                  </button>
                </small>
              </p>
            </section>
            <section
              v-if="noPaymentInfo"
              class="notification notification--danger"
            >
              <h6 class="title">Please, fill your payment information</h6>
              <p>
                <small>
                  You need to fill your payment information to work with the
                  service.
                  <router-link to="/edit" class="btn btn-link">
                    Settings
                  </router-link>
                </small>
              </p>
            </section> -->
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
import { useToastController } from 'bootstrap-vue-next';
import { mapActions } from 'vuex';
import ContentModeration from './components/ContentModeration';
import BalancesWidget from './components/BalancesWidget.vue';
import SspBalancesWidget from './components/SspBalancesWidget.vue';
import ParsersLog from './components/ParsersLog.vue';
import DbEntitiesWidget from './components/DbEntitiesWidget.vue';

export default {
  props: {
    notifications: {
      type: Array,
      default: function () {
        return [];
      }
    },

    emailVerified: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ContentModeration,
    BalancesWidget,
    SspBalancesWidget,
    ParsersLog,
    DbEntitiesWidget
  },

  data() {
    return {
      isOpen: false,
      noPaymentInfo: false,
      toastController: useToastController()
    };
  },

  computed: {
    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    },
    unreadNotifications() {
      return !this.emailVerified || this.notifications.length > 0;
    }
  },

  methods: {
    ...mapActions({
      fetchPaymentInfo: 'fetchPaymentInfo'
    }),
    toggleDropdown() {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
    hideHandler() {
      if (!this.isOpen) return;
      this.isOpen = false;
    },
    buildNotificationClasses({ type, unread }) {
      return !type
        ? {}
        : {
            [`notification--${type}`]: true,
            'is-unread': unread
          };
    },
    requestVerificationEmail() {
      this.$api.auth
        .post('auth/email/send-token', {
          email: this.$store.state.app.user.email
        })
        .then(
          () => {
            this.toastController.show?.({
              props: {
                body: this.$t('verificationSent'),
                title: `Success`,
                variant: 'success',
                autoHideDelay: 5000,
                solid: true
              }
            });
          },
          (e) => {
            this.toastController.show?.({
              props: {
                body: e.response.message,
                title: `Error`,
                variant: 'danger',
                autoHideDelay: 5000,
                solid: true
              }
            });
          }
        );
    }
  },

  created() {
    this.fetchPaymentInfo().then(
      () => {
        this.noPaymentInfo = false;
      },
      () => {
        this.noPaymentInfo = true;
      }
    );

    this.$bus.$on('payment-info-updated', () => {
      this.noPaymentInfo = false;
    });
  }
};
</script>

<style lang="scss" scoped>
.notification {
  padding: 0 1rem;
  position: relative;
  padding-bottom: 1rem;
  padding-top: 1rem;
  white-space: normal;

  &-dropdown {
    position: fixed;
    right: 0;
    top: $header-height;
    height: calc(100% - $header-height);
    display: block;
    background: var(--bs-body-bg);
    z-index: $zindex-fixed;
    border-left: 1px solid var(--bs-border-color);
    width: 100%;
    max-width: 390px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background: var(--bs-warning);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--bs-border-color);
  }

  &.is-unread {
    background: var(--bs-body-color-muted);
  }

  &__bell {
    position: relative;

    &.has-unread-notifications {
      &:after {
        content: ' ';
        display: block;
        background: var(--bs-warning);
        width: 8px;
        height: 8px;
        box-sizing: content-box;
        position: absolute;
        top: 12px;
        right: -2px;
        border-radius: 10px;
      }
    }
  }

  .title {
    color: $dark;
    font-weight: bold;
    margin-bottom: 0rem;
  }

  p {
    line-height: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    .btn {
      margin: 0;
      padding: 0;
      font-size: 12.8px;
      display: inline;
      border: none;
      line-height: 1rem;
      letter-spacing: normal;
      color: var(--bs-info);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .notification-dropdown {
    border-left: none;
  }
}
</style>

<template>
  <box-simple class="balances-widget">
    <template #header>
      <h4 class="mb-0">Low Balance Alerts</h4>
    </template>
    <template #actions>
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <b-form-checkbox
            v-model="showZeroBalances"
            name="zero-balances-toggler"
            size="sm"
            switch
            class="text-muted"
          >
            Zero balances
          </b-form-checkbox>
        </div>
        <div>
          <button
            v-if="!isLoading"
            class="btn-link-alt refresh-widget"
            @click="refresh()"
            title="Refresh"
          >
            <svg-icon icon="refresh" class="icon-lg" />
          </button>
          <b-spinner v-else variant="primary" type="grow" small />
        </div>
      </div>
    </template>

    <div class="p-4">
      <div v-if="!isLoading && this.error !== null" class="text-danger">
        {{ error }}
      </div>
      <div v-if="!showZeroBalances" :class="{ 'text-muted': isLoading }">
        <div
          v-for="user in lowBalanceAlertsList"
          :key="user.id"
          class="balance-alert-item mb-1"
        >
          <router-link
            :to="{ name: 'profile-admin', params: { userId: user.id } }"
            class="text-info"
          >
            {{ user.email }}
          </router-link>
          <span class="text-muted ms-1" style="font-size: 14px">
            ID: {{ user.id }}
          </span>
          - Balance: ${{ user.balance }}
        </div>
        <div v-if="lowBalanceSpoilerCount > 0" class="balance-alert-item">
          <span class="alerts-spoiler" @click="openModal(0)">
            +{{ lowBalanceSpoilerCount }} users
          </span>
          with low balance
        </div>
        <div
          v-if="!isLoading && !lowBalanceAlertsList.length"
          class="text-muted"
        >
          No users with low balances (&lt; $50)
        </div>
      </div>

      <div v-else :class="{ 'text-muted': isLoading }">
        <div
          v-for="user in zeroBalanceAlertsList"
          :key="user.id"
          class="balance-alert-item mb-1"
        >
          <router-link
            :to="{ name: 'profile-admin', params: { userId: user.id } }"
            class="text-info"
          >
            {{ user.email }}
          </router-link>
          <span class="text-muted ms-1" style="font-size: 14px">
            ID: {{ user.id }}
          </span>
          - Balance: {{ formatMoney(user.balance) }}
        </div>
        <div v-if="zeroBalanceSpoilerCount > 0" class="balance-alert-item">
          <span class="alerts-spoiler" @click="openModal(1)">
            +{{ zeroBalanceSpoilerCount }} users
          </span>
          with zero balance
        </div>
        <div
          v-if="!isLoading && !zeroBalanceAlertsList.length"
          class="text-muted"
        >
          No users with zero balances
        </div>
      </div>
    </div>

    <b-modal
      id="modal-balance-alerts"
      ref="modal-balance-alerts"
      no-footer
      title="Low Balance Alerts"
      size="md"
    >
      <b-tabs
        v-model="modalTabIndex"
        justified
        content-class="modal-balances-contet"
      >
        <b-tab title="Low">
          <b-table
            :fields="tableFields"
            :items="lowBalanceAlerts"
            class="balances-widget-table"
          >
            <template #cell(email)="data">
              <router-link
                :to="{
                  name: 'profile-admin',
                  params: { userId: data.item.id }
                }"
                class="text-info"
              >
                {{ data.value }}
              </router-link>
            </template>

            <template #cell(balance)="data">
              {{ formatMoney(data.value) }}
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Zero">
          <b-table
            :fields="tableFields"
            :items="zeroBalanceAlerts"
            class="balances-widget-table"
          >
            <template #cell(email)="data">
              <router-link
                :to="{
                  name: 'profile-admin',
                  params: { userId: data.item.id }
                }"
                class="text-info"
              >
                {{ data.value }}
              </router-link>
            </template>

            <template #cell(balance)="data">
              {{ formatMoney(data.value) }}
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-modal>
  </box-simple>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  data() {
    return {
      isLoading: true,
      balances: {},
      users: [],
      lowBalanceThreshold: 50,
      showZeroBalances: false,
      modalTabIndex: 0,
      tableFields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'ps-2'
        },
        {
          key: 'email',
          label: 'Email',
          thClass: 'ps-2'
        },
        {
          key: 'balance',
          label: 'Balance',
          thClass: 'ps-2'
        }
      ],
      error: null
    };
  },

  computed: {
    lowBalanceAlerts() {
      return this.users
        .filter(
          (user) => user.balance > 0 && user.balance <= this.lowBalanceThreshold
        )
        .sort((a, b) => a.balance - b.balance);
    },

    lowBalanceAlertsList() {
      return this.lowBalanceSpoilerCount > 0
        ? cloneDeep(this.lowBalanceAlerts).splice(0, 3)
        : this.lowBalanceAlerts;
    },

    lowBalanceSpoilerCount() {
      return this.lowBalanceAlerts.length > 4
        ? this.lowBalanceAlerts.length - 3
        : 0;
    },

    zeroBalanceAlerts() {
      return this.users
        .filter((user) => user.balance <= 0)
        .sort((a, b) => a.balance - b.balance);
    },

    zeroBalanceAlertsList() {
      return this.zeroBalanceSpoilerCount > 0
        ? cloneDeep(this.zeroBalanceAlerts).splice(0, 3)
        : this.zeroBalanceAlerts;
    },

    zeroBalanceSpoilerCount() {
      return this.zeroBalanceAlerts.length > 4
        ? this.zeroBalanceAlerts.length - 3
        : 0;
    }
  },

  methods: {
    async fetchAdvertBalances() {
      this.error = null;
      return await this.$api.adnet.get(`/payment/active-advert-balances`).then(
        (res) => {
          return cloneDeep(res);
        },
        () => {
          this.$alertError('Fetching balances error');
          this.error = 'Fetching balances error';
          return [];
        }
      );
    },

    async fetchUsers() {
      this.error = null;
      return this.$api.auth.get(`/users?limit=9999`).then(
        (res) => {
          if (!res.result) return [];
          const users = res.result.reduce((acc, user) => {
            if (this.balances.hasOwnProperty(user.id)) {
              acc.push({ ...user, balance: this.balances[user.id].balance });
            }
            return acc;
          }, []);
          return users;
        },
        () => {
          this.isLoading = false;
          this.$alertError('Fetching users error');
          this.error = 'Fetching users error';
          return [];
        }
      );
    },

    async refresh() {
      this.isLoading = true;
      this.balances = await this.fetchAdvertBalances();
      this.isLoading = false;
    },

    openModal(tabIndex) {
      this.modalTabIndex = tabIndex;
      this.$refs['modal-balance-alerts'].show();
    },

    formatMoney(val) {
      return val >= 0 ? `$${val}` : `-$${Math.abs(val)}`;
    }
  },

  async created() {
    this.isLoading = true;
    this.balances = await this.fetchAdvertBalances();
    this.users = await this.fetchUsers();
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
.balances-widget {
  min-height: 194px;
}

.balances-widget-table {
  thead th {
    border-top: 0 !important;
  }
}

.alerts-spoiler {
  color: #26a4ed;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.balance-alert-item {
  line-height: 26.5px;
}

.refresh-widget {
  position: relative;
  top: -2px;
}
</style>

<template>
  <box-simple>
    <template #header>
      <h4 class="mb-0">
        Addition / Modification of User Content ({{ total }})
      </h4>
    </template>
    <template #actions>
      <button
        v-if="!isLoading"
        class="btn-link-alt"
        @click="refresh()"
        title="Edit"
      >
        <svg-icon icon="refresh" class="icon-lg" />
      </button>
      <b-spinner v-else variant="primary" type="grow" small />
    </template>
    <div class="moderation-list p-4">
      <div
        class="moderation-list__item mb-1"
        :class="{ 'text-muted': isLoading }"
      >
        <span v-if="list.creatives > 0">
          You need to review
          <router-link :to="routes.creatives" class="text-info">
            {{ list.creatives }} creatives
          </router-link>
          that were recently added or modified
        </span>
        <span v-else class="text-muted">
          No new creatives needs to be approved, good job!
        </span>
      </div>
      <div
        class="moderation-list__item mb-1"
        :class="{ 'text-muted': isLoading }"
      >
        <span v-if="list.landings > 0">
          You need to review
          <router-link :to="routes.landings" class="text-info">
            {{ list.landings }} landings
          </router-link>
          that were recently added or modified
        </span>
        <span v-else class="text-muted">
          No new landings needs to be approved, good job!
        </span>
      </div>
      <div
        class="moderation-list__item mb-1"
        :class="{ 'text-muted': isLoading }"
      >
        <span v-if="list.payments > 0">
          You need to review
          <router-link :to="routes.payments" class="text-info">
            {{ list.payments }} payments
          </router-link>
          that were recently added or modified
        </span>
        <span v-else class="text-muted">
          No new payments needs to be approved, good job!
        </span>
      </div>
      <div class="moderation-list__item" :class="{ 'text-muted': isLoading }">
        <span v-if="list.sites > 0">
          You need to review
          <router-link :to="routes.sites" class="text-info">
            {{ list.sites }} websites
          </router-link>
          that were recently added or modified
        </span>
        <span v-else class="text-muted">
          No new websites needs to be approved, good job!
        </span>
      </div>
    </div>
  </box-simple>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  data() {
    return {
      isLoading: true,
      list: {
        creatives: 0,
        landings: 0,
        sites: 0,
        payments: 0
      },
      routes: {
        creatives: {
          name: 'creatives-list',
          query: {
            status: [2]
          }
        },
        landings: {
          name: 'admin-landing-groups',
          query: {
            status: [2],
            automated: [false]
          }
        },
        sites: {
          name: 'sites-review',
          query: {
            status: [1]
          }
        },
        payments: {
          name: 'payments-admin',
          query: {
            approved: [false]
          }
        }
      }
    };
  },

  computed: {
    total() {
      return (
        this.list.creatives +
        this.list.landings +
        this.list.sites +
        this.list.payments
      );
    }
  },

  methods: {
    async fetchModerateList() {
      return await this.$api.adnet.get(`/dsp/moderate-list`).then(
        (res) => {
          this.isLoading = false;
          return cloneDeep(res);
        },
        (e) => {
          this.isLoading = false;
          this.$alertError('Something went wrong');
        }
      );
    },

    async refresh() {
      this.isLoading = true;
      this.list = await this.fetchModerateList();
    }
  },

  async created() {
    this.list = await this.fetchModerateList();
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <b-alert
    v-model="isVisible"
    dismissible
    class="notification"
    @closed="hideNotification"
    variant="warning"
  >
    <p>
      <strong>Hi there!</strong>
    </p>
    <p>
      We’ve made some big updates to the site! 🎉 You might notice changes here
      and there. If something isn’t working as expected, let us know — we’re
      here to help!
    </p>
    <p class="mb-0">
      Reach out to
      <router-link to="/contacts">support</router-link>
      if you need assistance!
    </p>
  </b-alert>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true
    };
  },
  mounted() {
    this.isVisible = !this.IsNotificationHidden();
  },
  methods: {
    IsNotificationHidden() {
      return window.localStorage.getItem('hide-notification') === 'true';
    },
    hideNotification() {
      window.localStorage.setItem('hide-notification', 'true');
      this.isVisible = false;
    }
  }
};
</script>

<style lang="scss">
.notification {
  margin-bottom: 2rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }
}
</style>

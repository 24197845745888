<template>
  <div class="head-sorting ps-2">
    <div
      v-if="props.type === 'asc'"
      class="d-flex flex-column align-items-center head-sorting"
    >
      <svg-icon
        @click.stop
        icon="hp-sort-up"
        class="icon-xsm active"
        @click="sortBy = []"
      />
      <svg-icon
        @click.stop
        icon="hp-sort-down"
        class="icon-xsm"
        @click="sortBy = [{ key: props.field.key, order: 'desc' }]"
      />
    </div>

    <div
      v-else-if="props.type === 'desc'"
      class="d-flex flex-column align-items-center head-sorting"
    >
      <svg-icon
        @click.stop
        icon="hp-sort-up"
        class="icon-xsm"
        @click="sortBy = [{ key: field.key, order: 'asc' }]"
      />
      <svg-icon
        @click.stop
        icon="hp-sort-down"
        class="icon-xsm active"
        @click="sortBy = []"
      />
    </div>
    <div v-else class="d-flex flex-column align-items-center">
      <svg-icon
        @click.stop
        icon="hp-sort-up"
        class="icon-xsm"
        @click="sortBy = [{ key: field.key, order: 'asc' }]"
      />
      <svg-icon
        @click.stop
        icon="hp-sort-down"
        class="icon-xsm"
        @click="sortBy = [{ key: field.key, order: 'desc' }]"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  field: {
    type: Object,
    default: () => ({ key: '', label: '' })
  },
  type: {
    type: String,
    default: 'asc' | 'desc' | 'default'
  }
});

const sortBy = defineModel('sortBy', {
  type: Array,
  default: []
});
</script>

<style lang="scss" scoped>
.head-sorting {
  width: fit-content;
  display: inline-block;
  vertical-align: middle;
  i {
    cursor: pointer;
  }
  .active {
    color: var(--bs-primary);
  }
}
</style>

<template>
  <div class="header-control text-nowrap fw-bold">
    <router-link
      v-if="!isPublisher && $can('visit', 'Payments')"
      to="/payments"
      class="text-decoration-none"
    >
      <svg-icon icon="hp-dollar" class="icon-balance" />
      <b-spinner
        v-if="isBalanceLoading"
        class="balance-loader ms-1"
        variant="primary"
        type="grow"
        small
      />
      <span
        v-else
        class="align-center"
        :class="{ 'text-danger': isNegativeBalance }"
      >
        {{ formatNumber(userBalance.amount) + ' ' + userBalance.currency }}
      </span>
    </router-link>
    <router-link
      v-if="isPublisher"
      to="/payments"
      v-b-tooltip.hover.bottom="'Unpaid earnings'"
    >
      <svg-icon icon="hp-dollar" class="icon-balance" />
      <b-spinner
        v-if="isBalanceLoading"
        class="balance-loader ms-1"
        variant="primary"
        type="grow"
        small
      />
      <span style="margin-top: 2px" v-else>
        {{
          formatNumber(publisherUnpaidBalance.amount) +
          ' ' +
          publisherUnpaidBalance.currency
        }}
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    isPublisher: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      userBalance: (state) => state.app.userBalance,
      publisherUnpaidBalance: (state) => state.app.publisherUnpaidBalance,
      user: (state) => state.app.user,
      role: (state) => state.app.role,
      isBalanceLoading: (state) => state.app.isBalanceLoading
    }),

    isNegativeBalance() {
      return this.userBalance.amount < 0;
    }
  },

  methods: {
    formatNumber(val) {
      return this.$numeral(val).format('0,0.00');
    }
  },

  created() {
    if (this.isPublisher) {
      this.$store.dispatch('fetchPublisherUnpaidBalance');
    } else {
      this.$store.dispatch('fetchUserBalance', this.user.id);
    }

    this.$bus.$on('balance-loading', (val) => {
      console.log(val);
    });
  },

  watch: {
    'user.id': {
      handler(val) {
        if (!val) return;
        if (this.isPublisher) {
          this.$store.dispatch('fetchPublisherUnpaidBalance');
        } else {
          this.$store.dispatch('fetchUserBalance', this.user.id);
        }
      }
    },

    role() {
      if (!['advertiser', 'publisher'].includes(this.role)) return;
      if (this.role === 'publisher') {
        this.$store.dispatch('fetchPublisherUnpaidBalance');
      } else {
        this.$store.dispatch('fetchUserBalance', this.user.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.balance-loader {
}

a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-primary-tonal);
  padding: 4px 12px 4px 8px;
  border-radius: var(--bs-border-radius-lg);
  color: var(--bs-primary-tonal-color);
  min-height: var(--hp-control-size-sm);
}
:deep(.icon-balance) {
  svg {
    color: var(--bs-warning);
    width: 20px !important;
    height: 20px !important;
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
.box {
  background: var(--bs-container-bg);
  border: 1px solid var(--bs-border-color);
  padding: 2.5rem;
  border-radius: $border-radius;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(sm) {
  .box {
    padding: 1.5rem;
  }
}
</style>

<script>
// DEPRECATED: This component is deprecated.
// Use BoxSimple.vue instead.

import { defineComponent, h, onMounted } from 'vue';

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  },
  setup(props, { slots }) {
    onMounted(() => {
      console.warn(
        '[Deprecation Notice] The "Box" component is deprecated. Please use "BoxSimple" instead.'
      );
    });
    return () => {
      return h(
        props.tag,
        {
          class: {
            box: true
          }
        },
        slots.default ? slots.default() : []
      );
    };
  }
});
</script>

<template>
  <button class="sidebar__additional-btn" @click="$emit('sidebar-toggle')">
    <svg-icon
      :style="!expanded && { transform: 'rotate(180deg)' }"
      icon="icon-menu-arrow"
      class="icon-1x"
    />
  </button>
</template>

<script>
export default {
  name: 'ButtonSidebarToggle',
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  @extend %base-transition;
  display: flex;
  justify-content: end;
  margin-top: 4px;
  margin-right: 8px;
  color: $primary;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $primary;
  }
}
</style>

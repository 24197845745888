<template>
  <li>
    <div class="d-flex align-items-center gap-1 justify-content-between">
      <!-- <span
        :class="{
          'active-status me-3': true,
          active: this.item.active
        }"
      /> -->
      <router-link
        :to="{ name: 'edit-db-entity', params: { id: this.item.id } }"
      >
        #{{ this.item.id }} {{ this.item.name }}
      </router-link>
      <!-- <div class="update-time d-flex align-items-center me-4 ms-auto">
        <div>
          <svg-icon icon="clock" class="me-2" />
        </div>
        <small>{{ updateInterval }} min</small>
      </div> -->
      <div class="update-time d-flex align-items-center">
        <!-- <div>
          <svg-icon icon="calendar" class="me-2" />
        </div> -->
        <small>{{ updateTime }}</small>
      </div>
      <!-- <button
        v-if="this.item.error"
        class="text-danger cursor-pointer ms-4 btn m-0 p-0 border-0"
        @click="errorVisible = !errorVisible"
      >
        <svg-icon icon="error" />
      </button> -->
    </div>
    <b-collapse
      :id="`more-${this.item.id}`"
      v-model="errorVisible"
      class="ps-2 pe-2"
    >
      <pre class="mb-0">{{ this.item.error }}</pre>
    </b-collapse>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      errorVisible: false
    };
  },

  computed: {
    updateTime() {
      if (this.item.updateTime === '0001-01-01T00:00:00Z') {
        return 'No updates yet';
      }
      const newDate = new Date(this.item.updateTime);
      const offset = -(newDate.getTimezoneOffset() / 60);
      const offsetString = offset < 0 ? offset : `+${Math.round(offset)}`;
      return `${this.$date.format(
        this.item.updateTime,
        'DD.MM.YYYY HH:mm'
      )} (UTC ${offsetString})`;
    },

    updateInterval() {
      return this.item.updateInterval < 10
        ? `0${this.item.updateInterval}`
        : this.item.updateInterval;
    }
  }
};
</script>

<style lang="scss">
.update-time {
  line-height: 1.2;
}

.active-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $lighten;

  &.active {
    background-color: $success;
  }
}

h4 {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

pre {
  font-size: 0.7rem;
  line-height: 1.4;
  white-space: normal;
}
</style>

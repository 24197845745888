<template>
  <box-simple class="balances-widget">
    <template #header>
      <h4 class="mb-0">SSP Balances</h4>
    </template>
    <template #actions>
      <button
        v-if="!isLoading"
        class="btn-link-alt refresh-widget"
        @click="refresh()"
        title="Refresh"
      >
        <svg-icon icon="refresh" class="icon-lg" />
      </button>
      <b-spinner v-else variant="primary" type="grow" small />
    </template>
    <div class="p-4">
      <div v-if="!isLoading && this.error !== null" class="text-danger">
        {{ error }}
      </div>
      <div :class="{ 'text-muted': isLoading }">
        <div
          v-for="user in sspBalancesList"
          :key="user.id"
          class="balance-alert-item mb-1"
        >
          <router-link
            :to="{ name: 'profile-admin', params: { userId: user.id } }"
            class="text-info"
          >
            {{ user.email }}
          </router-link>
          <span class="text-muted ms-1" style="font-size: 14px">
            ID: {{ user.id }}
          </span>
          - Balance: ${{ user.balance }}
        </div>
        <div v-if="sspBalanceSpoilerCount > 0" class="balance-alert-item">
          <span class="alerts-spoiler" @click="openModal(0)">
            +{{ sspBalanceSpoilerCount }} SSP
          </span>
        </div>
        <div v-if="!isLoading && !sspBalancesList.length" class="text-muted">
          No SSP
        </div>
      </div>
    </div>

    <b-modal
      id="modal-balance-alerts"
      ref="modal-balance-alerts"
      no-footer
      title="Low Balance Alerts"
      size="md"
    >
      <b-table
        :fields="tableFields"
        :items="sspBalancesList"
        class="balances-widget-table"
      >
        <template #cell(email)="data">
          <router-link
            :to="{ name: 'profile-admin', params: { userId: data.item.id } }"
            class="text-info"
          >
            {{ data.value }}
          </router-link>
        </template>

        <template #cell(balance)="data">
          {{ formatMoney(data.value) }}
        </template>
      </b-table>
    </b-modal>
  </box-simple>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
export default {
  data() {
    return {
      isLoading: true,
      balances: {},
      users: [],
      lowBalanceThreshold: 50,
      modalTabIndex: 0,
      tableFields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'ps-2'
        },
        {
          key: 'email',
          label: 'Email',
          thClass: 'ps-2'
        },
        {
          key: 'balance',
          label: 'Balance',
          thClass: 'ps-2'
        }
      ],
      error: null
    };
  },

  computed: {
    sspBalancesList() {
      return this.sspBalanceSpoilerCount > 0
        ? cloneDeep(this.users).splice(0, 3)
        : this.users;
    },

    sspBalanceSpoilerCount() {
      return this.users.length > 4 ? this.users.length - 3 : 0;
    }
  },

  methods: {
    async fetchSSPBalances() {
      this.error = null;
      return await this.$api.adnet
        .get(`/payment/active-exchange-balances`)
        .then(
          (res) => {
            return cloneDeep(res);
          },
          () => {
            this.$alertError('Fetching SSP balances error');
            this.error = 'Fetching SSP balances error';
            return [];
          }
        );
    },

    async fetchUsers() {
      this.error = null;
      // request only users with role ad-exchange (ID 10)
      return this.$api.auth.get(`/users?limit=9999&role[]=10`).then(
        (res) => {
          if (!res.result) return [];
          const users = res.result.reduce((acc, user) => {
            if (this.balances.hasOwnProperty(user.id)) {
              acc.push({ ...user, balance: this.balances[user.id].balance });
            }
            return acc;
          }, []);
          return users;
        },
        () => {
          this.isLoading = false;
          this.$alertError('Fetching users error');
          this.error = 'Fetching users error';
          return [];
        }
      );
    },

    formatMoney(val) {
      return val >= 0 ? `$${val}` : `-$${Math.abs(val)}`;
    },

    async refresh() {
      this.isLoading = true;
      this.balances = await this.fetchSSPBalances();
      this.isLoading = false;
    },

    openModal() {
      this.$refs['modal-balance-alerts'].show();
    }
  },

  async created() {
    this.isLoading = true;
    this.balances = await this.fetchSSPBalances();
    this.users = await this.fetchUsers();
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
.balances-widget {
  min-height: 194px;
}

.balances-widget-table {
  thead th {
    border-top: 0 !important;
  }
}

.alerts-spoiler {
  color: #26a4ed;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.balance-alert-item {
  line-height: 26.5px;
}

.refresh-widget {
  position: relative;
  top: -2px;
}
</style>

import { mapActions } from 'vuex';

export const modes = {
  data() {
    return {
      modes: [
        // {
        // 	id: 'm:st',
        // 	alias: ':st',
        // 	title: 'Statistic reports by Name/Code',
        // 	groupTitle: 'Statistic reports',
        // 	roles: ['advert-admin', 'manager'],
        // 	route: {
        // 		path: '/statistics?rt=:code',
        // 		params: [
        // 			{
        // 				param: ':code',
        // 				valueKey: 'alias'
        // 			}
        // 		]
        // 	}
        // },
        {
          id: 'm:cm',
          alias: ':cm',
          title: 'Campaigns by ID/Name',
          groupTitle: 'Campaigns',
          getOptions: this.fetchAdvertCampaigns,
          searchParam: 'name',
          roles: ['advertiser', 'advert-admin', 'manager'],
          route: {
            path: '/campaigns/:id/edit',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:cr',
          alias: ':cr',
          title: 'Creatives by ID',
          groupTitle: 'Creatives',
          getOptions: async (params) => {
            let res = await this.fetchAdvertCreatives(params);
            return res.result.map((creative) => {
              return { id: creative.id, name: creative.description };
            });
          },
          searchParam: 'id',
          roles: ['advertiser', 'advert-admin', 'manager'],
          route: {
            path: '/creatives?edit=:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:l',
          alias: ':l',
          title: 'Landings by ID',
          groupTitle: 'Landings',
          getOptions: async (params) => {
            let res = await this.fetchLandings(params);
            return res.result.map((landing) => {
              return { id: landing.id, name: landing.landingGroup.name };
            });
          },
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/landings?edit=:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:lg',
          alias: ':lg',
          title: 'Landing groups by ID',
          groupTitle: 'Landing groups',
          getOptions: this.fetchLandingGroups,
          searchParam: 'name',
          roles: ['advertiser'],
          route: {
            path: '/landings?edit=:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:rtbc',
          alias: ':rtbc',
          title: 'RTB Campaigns by ID/Name',
          groupTitle: 'RTB campaigns',
          getOptions: this.fetchRtbCampaigns,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/rtb-campaigns/:id/edit',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:au',
          alias: ':au',
          title: 'Ad Units by ID/Name',
          groupTitle: 'Ad Units',
          getOptions: this.fetchAdunits,
          searchParam: 'name',
          roles: ['publisher', 'advert-admin', 'manager'],
          route: {
            path: '/adunit/edit/:type/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              },
              {
                param: ':type',
                valueKey: (unit) => {
                  return unit.type.name.toLowerCase();
                }
              }
            ]
          },
          hideId: true
        },
        {
          id: 'm:s',
          alias: ':s',
          title: 'Sites by ID/Name',
          groupTitle: 'Sites',
          getOptions: this.fetchPubSites,
          searchParam: 'name',
          roles: ['publisher'],
          route: {
            path: '/site-edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:t',
          alias: ':t',
          title: 'Targets by ID/Name',
          groupTitle: 'Targets',
          getOptions: this.fetchTargets,
          searchParam: 'name',
          roles: ['publisher', 'advert-admin', 'manager'],
          route: {
            path: '/targets/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:dd',
          alias: ':dd',
          title: 'Direct deals by ID/Name',
          groupTitle: 'Direct deals',
          getOptions: this.fetchDirectDeals,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/direct-deals/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:ar',
          alias: ':ar',
          title: 'Auto rules by ID/Name',
          groupTitle: 'Auto rules',
          getOptions: this.fetchAutoRules,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/auto-rules/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:u',
          alias: ':u',
          title: 'User by ID/Email',
          groupTitle: 'Users',
          getOptions: async (params) => {
            let res = await this.$api.auth.get(`/users`, params);
            return res.result.map((user) => {
              return { id: user.id, name: user.email };
            });
          },
          searchParam: 'email',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/users/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:f',
          alias: ':f',
          title: 'DSP Filters by ID/Name',
          groupTitle: 'DSP Filters',
          getOptions: this.fetchDSPFilters,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/filters/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:dspf',
          alias: ':dspf',
          title: 'DSP Feeds by ID/Name',
          groupTitle: 'DSP Feeds',
          getOptions: this.fetchDSPFeeds,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/feed/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:sspf',
          alias: ':sspf',
          title: 'SSP Feeds by ID/Name',
          groupTitle: 'SSP Feeds',
          getOptions: this.fetchSSPFeeds,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/ssp-feed/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:dspfb',
          alias: ':dspfb',
          title: 'DSP Feed bindings by ID/Name',
          groupTitle: 'DSP Feed bindings',
          getOptions: this.fetchDSPFeedBindings,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/feed-bindings/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:dfb',
          alias: ':dfb',
          title: 'Direct feed bindings by ID/Name',
          groupTitle: 'Direct feed bindings',
          getOptions: this.fetchDSPDirectFeedBindings,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/direct-feed-bindings/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:ssp',
          alias: ':ssp',
          title: 'SSP by ID/Name',
          groupTitle: 'SSP',
          getOptions: this.fetchSSP,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/ssp/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:dsp',
          alias: ':dsp',
          title: 'DSP by ID/Name',
          groupTitle: 'DSP',
          getOptions: this.fetchDSP,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/dsp/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        },
        {
          id: 'm:ep',
          alias: ':ep',
          title: 'Exchange points by ID/Name',
          groupTitle: 'Exchange points',
          getOptions: this.fetchExchangePoints,
          searchParam: 'name',
          roles: ['advert-admin', 'manager'],
          route: {
            path: '/admin/exchange-points/edit/:id',
            params: [
              {
                param: ':id',
                valueKey: 'id'
              }
            ]
          }
        }
      ]
    };
  },

  computed: {
    availableModes() {
      return this.modes.filter((mode) =>
        mode.roles.includes(this.$store.getters.role)
      );
    }
  },

  methods: {
    ...mapActions({
      fetchAdvertCampaigns: 'advert/fetchAdvertCampaigns',
      fetchAdvertCreatives: 'advert/fetchAdvertCreatives',
      fetchLandings: 'advert/fetchLandings',
      fetchLandingGroups: 'advert/fetchLandingGroups',
      fetchRtbCampaigns: 'advert/fetchRtbCampaigns',
      fetchAdunits: 'advert/fetchAdunits',
      fetchTargets: 'advert/fetchTargets',
      fetchPubSites: 'advert/fetchPubSites',
      fetchDirectDeals: 'advert/fetchDirectDeals',
      fetchAutoRules: 'advert/fetchAutoRules',
      fetchDSPFilters: 'advert/fetchDSPFilters',
      fetchDSPFeeds: 'advert/fetchDSPFeeds',
      fetchSSPFeeds: 'advert/fetchSSPFeeds',
      fetchDSPFeedBindings: 'advert/fetchDSPFeedBindings',
      fetchDSPDirectFeedBindings: 'advert/fetchDSPDirectFeedBindings',
      fetchSSP: 'advert/fetchSSP',
      fetchDSP: 'advert/fetchDSP',
      fetchExchangePoints: 'advert/fetchExchangePoints',
      fetchReports: 'advert/fetchReports'
    })
  }
};

export const addRoutes = {
  data() {
    return {
      addRoutes: [
        {
          id: 'payments',
          title: 'Payments',
          alias: 'pay',
          path: '/payments',
          roles: ['advertiser', 'advert-admin', 'manager', 'publisher']
        },
        {
          id: 'edit-profile',
          title: 'Settings',
          alias: 'set',
          path: '/edit',
          roles: ['advertiser', 'advert-admin', 'manager', 'publisher']
        },
        {
          id: 'campaign-add',
          title: 'Add Campaign',
          alias: 'a:cm',
          path: '/campaigns/add',
          roles: ['advertiser', 'advert-admin', 'manager']
        },
        {
          id: 'creative-add',
          title: 'Add Creative',
          alias: 'a:cr',
          path: '/creatives?add=1',
          roles: ['advertiser', 'advert-admin', 'manager']
        },
        {
          id: 'landing-group-add',
          title: 'Add Landing group',
          alias: 'a:lg',
          path: '/landings?add=1',
          roles: ['advertiser']
        },
        {
          id: 'rtb-campaign-add',
          title: 'Add RTB campaign',
          alias: 'a:rcm',
          path: '/rtb-campaigns/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'adunit-banner-add',
          title: 'Add Banner ad unit',
          alias: 'a:au:b',
          path: '/adunit/add/banner',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'adunit-video-add',
          title: 'Add Video ad unit',
          alias: 'a:au:v',
          path: '/adunit/add/video',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'adunit-popunder-add',
          title: 'Add Popunder ad unit',
          alias: 'a:au:p',
          path: '/adunit/add/popunder',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'adunit-native-add',
          title: 'Add Native ad unit',
          alias: 'a:au:n',
          path: '/adunit/add/native',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'adunit-interstitial-add',
          title: 'Add Interstitial ad unit',
          alias: 'a:au:i',
          path: '/adunit/add/interstitial',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'adunit-videoslider-add',
          title: 'Add VideoSlider ad unit',
          alias: 'a:au:vs',
          path: '/adunit/add/videoslider',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'adunit-link-add',
          title: 'Add Link ad unit',
          alias: 'a:au:l',
          path: '/adunit/add/link',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'adunit-outstreamvideo-add',
          title: 'Add OutstreamVideo ad unit',
          alias: 'a:au:ov',
          path: '/adunit/add/outstreamvideo',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'target-add',
          title: 'Add target',
          alias: 'a:t',
          path: '/targets/add',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'retarget-group-add',
          title: 'Add Retarget group',
          alias: 'a:rtg',
          path: '/retarget-groups/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'direct-deals-add',
          title: 'Add Direct deal',
          alias: 'a:dd',
          path: '/direct-deals/add',
          roles: ['advert-admin', 'manager'],
          grants: []
        },
        {
          id: 'code-template-add',
          title: 'Add Code template',
          alias: 'a:ct',
          path: '/code-templates/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'site-add',
          title: 'Add Site',
          alias: 'a:s',
          path: '/site-add',
          roles: ['publisher', 'advert-admin', 'manager']
        },
        {
          id: 'auto-rule-add',
          title: 'Add Auto rule',
          alias: 'a:ar',
          path: '/admin/auto-rules/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'filter-add',
          title: 'Add Filter',
          alias: 'a:f',
          path: '/admin/filters/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'dsp-feed-add',
          title: 'Add DSP Feed',
          alias: 'a:dspf',
          path: '/admin/feed/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'ssp-feed-add',
          title: 'Add SSP Feed',
          alias: 'a:sspf',
          path: '/admin/ssp-feed/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'dspfb-add',
          title: 'Add DSP Feed binding',
          alias: 'a:dspfb',
          path: '/admin/feed-bindings/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'dfb-add',
          title: 'Add Direct feed binding',
          alias: 'a:dfb',
          path: '/admin/direct-feed-bindings/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'ssp-add',
          title: 'Add SSP',
          alias: 'a:ssp',
          path: '/admin/ssp/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'dsp-add',
          title: 'Add DSP',
          alias: 'a:dsp',
          path: '/admin/dsp/add',
          roles: ['advert-admin', 'manager']
        },
        {
          id: 'exchange-point-add',
          title: 'Add Exchange point',
          alias: 'a:ep',
          path: '/admin/exchange-points/add',
          roles: ['advert-admin', 'manager']
        }
      ]
    };
  }
};

export const commands = {
  data() {
    return {
      commands: [
        {
          id: 'c:settings',
          title: '/settings',
          alias: '/s',
          action: () => {
            this.$refs.settings.showModal('settings');
          }
        },
        {
          id: 'c:help',
          title: '/help',
          alias: '/h',
          action: () => {
            this.$refs.settings.showModal('help');
          }
        }
      ]
    };
  }
};

<template>
  <i v-if="iconHtml" :class="['icon', `icon-${icon}`]" v-html="iconHtml"></i>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    fallbackIcon: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconHtml() {
      try {
        return require(`@/assets/icons/${this.icon}.svg`);
      } catch (e) {
        return this.fallbackHtml;
      }
    },
    fallbackHtml() {
      try {
        return require(`@/assets/icons/${this.fallbackIcon}.svg`);
      } catch (e) {
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.icon {
  display: inline-block;
  text-align: center;
  line-height: 0;
  position: relative;

  svg {
    stroke: currentColor;
    width: 1em;
    height: 1em;

    title {
      display: none;
    }
  }
}

.icon-color-normal {
  svg {
    color: var(--bs-icon-color);
  }
}

.icon-menu svg {
  width: 20px;
  height: 20px;
  min-width: 20px;
  position: relative;
  top: -1px;
  .hp-icon {
    stroke-width: 1.5 !important;
  }
}
.icon-menu-sm {
  width: 16px;
  height: 16px;
  min-width: 16px;
  position: relative;
}

.icon-menu-lg svg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  .hp-icon {
    stroke-width: 1.25 !important;
  }
}

.icon-lg svg {
  font-size: 1.5rem;
}

.icon-md svg {
  font-size: 1.25rem;
}

.icon-xl svg {
  font-size: 1.66666em;
}

.icon-xs svg {
  width: 0.75em;
  height: 0.75em;
  vertical-align: 0em;
}

.icon-xxs svg {
  width: 0.5em;
  height: 0.5em;
  vertical-align: 0em;
}

.icon-sm svg {
  width: 0.875em;
  height: 0.875em;
}

.icon-xsm svg {
  width: 0.6em;
  height: 0.6em;
}

.icon-1x svg {
  width: 1em;
  height: 1em;
}

.icon-1-5x svg {
  width: 1.5em;
  height: 1.5em;
}

.icon-2x svg {
  width: 2em;
  height: 2em;
}

.icon-2-5x svg {
  width: 2.5em;
  height: 2.5em;
}

.icon-3x svg {
  width: 3em;
  height: 3em;
}

.icon-4x svg {
  width: 4em;
  height: 4em;
}

.icon-5x svg {
  width: 5em;
  height: 5em;
}

.icon-6x svg {
  width: 6em;
  height: 6em;
}

.icon-7x svg {
  width: 7em;
  height: 7em;
}

.icon-8x svg {
  width: 8em;
  height: 8em;
}

.icon-9x svg {
  width: 9em;
  height: 9em;
}

.icon-10x svg {
  width: 0em;
  height: 0em;
}
</style>

<template>
  <div>
    <a
      href="#"
      v-if="isAdmin"
      aria-controls="collapse-dsp"
      @click.prevent="visible = !visible"
    >
      <svg-icon :icon="icon" class="icon-menu" />
      <span>{{ title }}</span>
      <svg-icon
        icon="hp-small-shevron-right"
        class="icon-menu-sm ms-auto"
        :class="{ 'chevron-active': visible }"
      />
    </a>
    <b-collapse
      class="inner-menu py-1"
      :id="`collapse-${id}`"
      v-model="visible"
      v-if="isAdmin"
    >
      <router-link
        v-for="(item, index) in collapsingItems"
        :to="item.path"
        :title="item.meta && item.meta.title"
        :key="index"
        class="inner"
      >
        <svg-icon
          :icon="item.meta && item.meta.icon"
          class="icon-menu icon-md"
        />
        <span>{{ item.meta && item.meta.title }}</span>
      </router-link>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'sites'
    },

    items: {
      type: Array,
      required: true
    },

    list: {
      type: Array,
      default: () => []
    },

    id: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    mainNavExpanded: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    return {
      visible: false
    };
  },

  watch: {
    mainNavExpanded(val) {
      if (!val) this.visible = false;
    }
  },

  computed: {
    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    },

    isAdmin() {
      return ['advert-admin', 'manager'].includes(this.$store.getters.role);
    },

    collapsingItems() {
      if (this.list.length > 0) return this.list;
      return this.items.filter((item) => item.name.includes(this.id));
    }
  },

  created() {
    if (this.$route.name && this.$route.name.includes(this.id)) {
      this.visible = true;
    }
  }
};
</script>

<style lang="scss">
.chevron-active {
  transform: rotate(90deg);
}

.inner-menu {
  background-color: var(--hp-menu-secondary-bg, var(--bs-surface-secondary-bg));
  border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
}
</style>

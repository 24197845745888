<template>
  <div>
    <b-modal
      id="ns-settings"
      ref="ns-settings"
      title="Settings"
      no-header
      no-footer
    >
      <b-tabs content-class="mt-3">
        <b-tab title="Settings" :active="activeTab === 'settings'">
          <settings-form @form-submit="settingsUpdate" />
        </b-tab>
        <b-tab title="Help" :active="activeTab === 'help'">
          <help />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import Help from './Help.vue';
import SettingsForm from './SettingsForm.vue';

export default {
  components: {
    SettingsForm,
    Help
  },

  data() {
    return {
      activeTab: 'settings'
    };
  },

  methods: {
    showModal(tab = 'settings') {
      this.activeTab = tab;
      this.$refs['ns-settings'].show();
    },

    hideModal() {
      this.$refs['ns-settings'].hide();
    },

    settingsUpdate() {
      this.$emit('settings-update');
      this.hideModal();
    }
  }
};
</script>

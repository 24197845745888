<template>
  <box-simple class="flex-grow-1">
    <template #header>
      <h4 class="mb-0">Parsers log</h4>
    </template>
    <div class="p-4">
      <b-form-group class="mt-3 mb-0">
        <b-form-input
          id="search"
          name="search"
          type="text"
          placeholder="Search"
          size="md"
          v-model="query"
        />
      </b-form-group>
      <div v-if="parsers && parsers.length">
        <ul class="list-unstyled p-2 mb-0" style="overflow-y: scroll">
          <li v-for="parser in parsersList" :key="parser.id">
            <div class="d-flex align-items-center p-2">
              <span
                :class="{
                  'active-status me-3': true,
                  'ok-status': parser.stateOk || false
                }"
              ></span>
              <h4 class="parser-name m-0 me-4">{{ parser.parserName }}</h4>
              <div
                class="update-time d-flex align-items-center me-4 ms-auto"
                v-b-tooltip.hover
                :title="`Current status: ${parser.currentStatus}`"
              >
                <div>
                  <svg-icon :icon="parser.statusIcon" class="me-2" />
                </div>
              </div>
              <div
                class="update-time avg-time d-flex align-items-center me-4 ms-auto"
              >
                <div>
                  <svg-icon icon="clock" class="me-2" />
                </div>
                <small v-b-tooltip.hover title="Avg. time">{{
                  parser.avgTime
                }}</small>
                <small
                  class="ms-1"
                  v-b-tooltip.hover
                  :title="`Last started at: ${parser.lastStartedAt}`"
                >
                  ({{ parser.lastStartedAtShort }})
                </small>
              </div>
              <div
                class="update-time d-flex align-items-center me-4 ms-auto"
                v-b-tooltip.hover
                title="Starts count"
              >
                <div>
                  <svg-icon icon="counter" class="me-2 text-muted" />
                </div>
                <small class="item-value">{{ parser.started }}</small>
              </div>
              <div
                class="update-time d-flex align-items-center"
                v-b-tooltip.hover
                title="Errors count"
              >
                <div>
                  <svg-icon
                    icon="error"
                    class="me-2"
                    :class="{
                      'text-danger': parser.errors > 0,
                      'text-muted': parser.errors === 0
                    }"
                  />
                </div>
                <small class="item-value">{{ parser.errors }}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="text-center p-2" v-else>No logs</div>
    </div>
    <template #footer v-if="parsers && parsers.length">
      <div class="d-flex border-top" v-if="parsers && parsers.length">
        <div class="pagination p-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="limit"
            class="mb-0"
          />
        </div>
        <div class="d-flex flex-row-reverse ms-auto p-2">
          <b-form-select
            v-model="limit"
            :options="pageOptions"
            style="width: 65px"
          />
        </div>
      </div>
    </template>
  </box-simple>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import BoxSimple from '@/components/Layout/BoxSimple.vue';
export default {
  components: { BoxSimple },
  data() {
    return {
      query: '',
      limit: 10,
      currentPage: 1,
      totalRows: 0,
      pageOptions: [5, 10, 20],
      parsers: [],
      currentParsers: []
    };
  },

  computed: {
    parsersList() {
      if (this.currentParsers.length === 0) return [];
      return this.currentParsers[this.currentPage - 1];
    }
  },

  methods: {
    getParserAvgTime(time) {
      if (time < 60) {
        return time < 10 ? `0${parseInt(time)} sec` : `${parseInt(time)} sec`;
      } else {
        const timeInMins = parseInt(time / 60);
        return timeInMins < 10 ? `0${timeInMins} min` : `${timeInMins} min`;
      }
    },

    getStatusIcon(status, avgTime) {
      if (status.toLowerCase() === 'finished') return 'flag';
      return avgTime >= 3600 ? 'snail' : 'run';
    },

    processParsers(parsers) {
      if (!parsers || !parsers.length) return [];
      let parsersList = parsers.map((parser, index) => {
        let parserData = cloneDeep(parser);

        parserData.id = index;
        parserData.avgTime = this.getParserAvgTime(parser.avgTime);
        parserData.stateOk = parser.avgTime < 3600;
        parserData.lastStartedAt = this.$date.format(
          parser.lastStartedAt,
          'DD.MM.YYYY HH:mm'
        );
        parserData.lastStartedAtShort = this.$date.format(
          parser.lastStartedAt,
          'HH:mm'
        );
        parserData.currentStatus = parser.currentStatus.toLowerCase();
        parserData.statusIcon = this.getStatusIcon(
          parser.currentStatus,
          parser.avgTime
        );

        return parserData;
      });

      parsersList.sort((a, b) => b.errors - a.errors);
      console.log(parsersList);
      return parsersList;
    },

    chunk(arr) {
      return Array.from(
        { length: Math.ceil(arr.length / this.limit) },
        (v, i) => arr.slice(i * this.limit, i * this.limit + this.limit)
      );
    },

    setCurrentParsers(parsers) {
      this.currentParsers = this.chunk(parsers);
    },

    async setParsers() {
      if (!this.parsers.length) {
        this.parsers = this.processParsers(await this.fetchParsersLog());
      }
      this.totalRows = this.parsers.length;
      this.setCurrentParsers(this.parsers);
    },

    async fetchParsersLog() {
      return this.$api.adnet.get('/stats/parsers-logs').then((res) => {
        return res.parsersLogs && res.parsersLogs.length ? res.parsersLogs : [];
      });
    }
  },

  watch: {
    limit() {
      this.setCurrentParsers(this.parsers);
    },

    query(val) {
      if (val.length > 2) {
        const filtered = cloneDeep(this.parsers).filter((parser) =>
          parser.parserName.toLowerCase().includes(val.toLowerCase())
        );
        this.totalRows = filtered.length;
        this.setCurrentParsers(filtered);
      } else {
        this.setParsers();
      }
    }
  },

  async created() {
    await this.setParsers();
  }
};
</script>

<style lang="scss">
.update-time {
  line-height: 1.2;

  &.avg-time {
    min-width: 110px;
  }
}

.active-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $danger;

  &.ok-status {
    background-color: $success;
  }
}

.parser-name {
  text-transform: capitalize;
  width: 110px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-value {
  width: 30px;
}
</style>

<template>
  <b-modal
    id="fake-login-form"
    title="Login as"
    ref="fake-login-modal"
    button-size="sm"
    @ok="fakeLogin"
    @hide="selectedUser = []"
    ok-variant="success"
    ok-title="Login"
  >
    <b-form-group label-for="fl-users">
      <div class="position-relative">
        <multi-select
          v-if="users.length"
          id="fl-users"
          btn-label="User"
          size="md"
          :options="{ multi: false }"
          :select-options="users"
          search
          v-model="selectedUser"
        />
      </div>
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import * as config from '@/../config/index.js';
import * as auth from '@/utils/auth';

export default {
  data() {
    return {
      users: [],
      selectedUser: []
    };
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    openForm() {
      this.$refs['fake-login-modal'].show();
    },

    fetchUsers() {
      this.$api.auth
        .get(`/users`, {
          params: { orderBy: 'created_at', order: 2, limit: 9999 }
        })
        .then(
          (data) => {
            if (data.result) {
              this.users = data.result
                .filter((user) => user.email !== this.user.email)
                .map((user) => {
                  return {
                    id: user.id,
                    name: user.email
                  };
                });
            }
          },
          (e) => {
            e.response.data &&
              this.$notify('alert', {
                text: e.response.data.error.message,
                variant: 'danger',
                time: 5000
              });
            this.$router.push({ name: 'dashboard' });
          }
        );
    },

    async getFakeAccess(userId) {
      return await this.$api.auth
        .post(`/auth/impersonate-login/${userId}`, {
          fp: auth.getFp(auth.readUser())
        })
        .then(
          (res) => {
            return res;
          },
          (e) => {
            e.response.data &&
              this.$notify('alert', {
                text: e.response.data.error.message,
                variant: 'danger',
                time: 5000
              });
            return null;
          }
        );
    },

    async fakeLogin() {
      if (!this.selectedUser.length) return false;
      const userId = this.selectedUser[0].id;
      const fakeAccess = await this.getFakeAccess(userId);
      console.log(fakeAccess);
      let userFp =
        auth.getFp(fakeAccess.impersonateLogin) || Date.now().toString();
      auth.saveFakeLogin(this.user.email, fakeAccess.impersonateLogin);

      try {
        if (!auth.getFp(fakeAccess.impersonateLogin)) {
          auth.saveFp(userFp, fakeAccess.impersonateLogin);
        }

        this.$api.updateCredentials({
          email: fakeAccess.impersonateLogin,
          token: fakeAccess.accessToken,
          refreshToken: fakeAccess.refreshToken
        });

        await this.$store.dispatch('fetchUser').then(
          (res) => {
            if (res) {
              this.switchRole(config.default.roles.get(res.roles[0]));
              // this.$router.go();
            }
          },
          (e) => e
        );

        this.setupUser();
      } catch (e) {
        console.log(e);
        auth.saveFakeLogin();
      }
    },

    getRouteName() {
      switch (auth.readActiveRole()) {
        case 'admin':
          return 'users';
        case 'advert-admin':
          return 'stat';
        case 'ad-exchange':
          return 'stat';
        default:
          return 'dashboard';
      }
    },

    setupUser() {
      if (!auth.readActiveRole() && auth.readToken()) {
        const adminIndex = this.$store.state.app.user.roles.indexOf(
          this.$config.rolesIds['admin']
        );

        this.$store.dispatch(
          'switchRole',
          this.$store.state.app.user.roles[adminIndex] ||
            this.$store.state.app.user.roles[0]
        );
      } else {
        this.$store.dispatch('switchRole', auth.readActiveRole());
      }

      const entryUrl = localStorage.getItem('entryUrl');

      if (entryUrl) {
        window.location.href = entryUrl;
      } else {
        this.$router.push({
          name: this.getRouteName()
        });
      }
    },

    switchRole(role) {
      this.$bus.$emit('switch-role:start');
      this.$store.dispatch('switchRole', role).then(
        (res) => {
          this.$bus.$emit('switch-role:end');
          if (!this.$route.meta.roles) return res;
          this.$router.push({
            name: this.getRouteName()
          });
          return res;
        },
        (e) => e
      );
    }
  },

  created() {
    this.fetchUsers();
  }
};
</script>
